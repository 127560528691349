import React, {Component} from 'react';
import withWindowDimensions, {windowDimensionsPropTypes} from '../../components/withWindowDimensions';

class PairInstructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            areInstructionsVisible: !props.isSmallScreenWidth,
        };
    }

    render() {
        const shouldShowInstructions = !this.props.isSmallScreenWidth || this.state.areInstructionsVisible;
        return (
            <div className="row">
                {this.props.isSmallScreenWidth && (
                    <div className="d-flex w-100 justify-content-center mb-2">
                        <button
                            type="button"
                            onClick={() => this.setState(prevState => ({areInstructionsVisible: !prevState.areInstructionsVisible}))}
                            className="bg-none border-0 text-secondary-color font-italic"
                        >
                            Having trouble deciding?
                        </button>
                    </div>
                )}
                {shouldShowInstructions && (
                    <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2" id="instructions">
                        <p>
                            If you&apos;re having trouble deciding who should be paid more, you&apos;ll need to
                            {' '}
                            do some research. Here are some ideas:
                        </p>

                        <ul>
                            <li>
                                Think through who has consistently added more value to the team. Value comes in all
                                {' '}
                                different forms so take a step back and put yourself in that person&apos;s shoes. How
                                {' '}
                                many customers have they helped, how many employees have they helped, how have they
                                {' '}
                                streamlined processes, etc.
                            </li>
                            <li>
                                Consider how their daily behaviors are net positive to the team. Do they,
                                <ul>
                                    <li>
                                        speed up and enhance conversations by quickly grasping and improving upon the ideas of others,
                                        {' '}
                                        asking relevant questions?
                                    </li>
                                    <li>
                                        bring unique, relevant insights that advance conversations toward the ultimate solution?
                                    </li>
                                    <li>
                                        produce excellent quality results which inspire the rest of the team?
                                    </li>
                                    <li>
                                        raise proposals that excite, inspire, and change company culture or advance strategy?
                                    </li>
                                    <li>
                                        actively pay attention to peers and proactively offer guidance, training, and assistance?
                                    </li>
                                    <li>
                                        step up to define, improve, and automate our processes, as well as constructively call out
                                        {' '}
                                        opportunities for human error before they are made?
                                    </li>
                                    <li>
                                        go above and beyond to do whatever is necessary to get the job done?
                                    </li>
                                    <li>
                                        continue growing their skills and abilities, as well as consistently changing their role
                                        {' '}
                                        and working on bigger and better things, thereby bringing new capabilities to the company?
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Read through the description they have written above. Is there anything listed there you
                                {' '}
                                want to research further or ask around about?
                            </li>
                            <li>
                                What big projects have they worked on recently?
                                <ul>
                                    <li>
                                        Have they sent out design docs? Emails to all?
                                    </li>
                                    <li>
                                        <b>Pro Tip:</b>
                                        {'  Use the links for '}
                                        <code>#whatsnext</code>
                                        {' and '}
                                        <code>strategy@</code>
                                        {' '}
                                        in their profile above
                                        {' '}
                                        to search quickly for these
                                    </li>
                                    <li>
                                        <b>Pro Tip:</b>
                                        {' '}
                                        Research their Recent Top Projects to better understand what they have chosen to focus on.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                How have they performed day to day
                                <ul>
                                    <li>
                                        You can use Chronos&apos; command:
                                        {' '}
                                        <code>10am show @user 90</code>
                                        {' '}
                                        to see their last 90 days of 10am updates in Chronos in new.expensify.com
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Think through how they have grown recently
                                <ul>
                                    <li>
                                        Look into their G&R progression and think about whether they have become much
                                        {' '}
                                        more valuable
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Look at what teams they are on and consider whether they are contributing to high value chores.
                                <ul>
                                    <li>
                                        Think about how often those teams have chores assigned?
                                        {' '}
                                        <i>Weekly, Monthly, Quarterly, Daily, Hourly, intermittent etc.</i>
                                    </li>
                                    <li>
                                        How many people are in the team?
                                        {' '}
                                        <i>This gives an idea of how often members were assigned chores.</i>
                                    </li>
                                    <li>
                                        How much effort and attention do the chores on that team take?
                                        {' '}
                                        <i>
                                            5 minutes of direct focus, one week of intermittent focus, 6 hours of indirect focus,
                                            {' '}
                                            etc.?
                                        </i>
                                    </li>
                                    <li>
                                        How crucial do you think the chores on that team are?
                                        {' '}
                                        What would happen if we stopped doing them?
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            Ultimately, this is your vote, these are just some suggestions on some things to think
                            {' '}
                            about and tools that you can use to research your colleagues a bit more.
                        </p>
                    </div>
                )}
            </div>
        );
    }
}

PairInstructions.propTypes = windowDimensionsPropTypes;

export default withWindowDimensions(PairInstructions);
